<template>
  <app-page title="Drag">
    <div>
      <h3>Transition</h3>
      <draggable
        class="list-group"
        tag="ul"
        v-model="list"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <li
            class="list-group-item"
            v-for="element in list"
            :key="element.order"
          >
            {{ element.name }}
          </li>
        </transition-group>
      </draggable>
    </div>
  </app-page>
</template>

<script>
import draggable from "vuedraggable";
const message = [
  "vue.draggable",
  "draggable",
  "component",
  "for",
  "vue.js 2.0",
  "based",
  "on",
  "Sortablejs",
];
export default {
  name: "test",
  components: {
    draggable,
  },
  data() {
    return {
      list: message.map((name, index) => {
        return { name, order: index + 1 };
      }),
      drag: false,
    };
  },
  methods: {
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  mounted() {
    document.querySelector("body").classList.add("overflow-hidden");
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("overflow-hidden");
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.sortable-chosen,
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  transform: skew(-9deg, 0deg);
}
.list-group {
  min-height: 20px;
  list-style: none;
  padding: 0;

  span {
    display: flex;
    overflow: auto;
    padding: 1.5rem 0;
    background: #333;
  }

  &-item {
    cursor: move;
    border: 1px solid #ddd;
    background-color: #f3f3f3;

    height: 150px;
    min-width: 140px;
    margin: 0.5rem;
    display: grid;
    place-items: center;
  }
}

.list-group-item i {
  cursor: pointer;
}
</style>
